import { mapGetters } from "vuex";
import { getInputType } from "@/utils/forms";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
export default {
  data: () => ({
    isLoading: true,
    beForm: {},
    customInputs: {}, // used to add custom inputs dynamically
    // ok: true, // status flag if error then false
    // message: "", // error message to display
  }),
  methods: {
    // return a promise, shall be called in chain: fetchShowForm().then( fetch() )
    fetchShowForm(repository, resourceId = this.resourceId, queryString = "") {
      let resource = this.resource;
      //   this.$parent.resource ||
      //   this.$parent.$parent.resource ||
      //   this.$parent.$parent.$parent.resource ||
      //   this.$parent.$parent.$parent.$parent.resource ||
      //   this.$parent.$parent.$parent.$parent.$parent.resource ||
      //   this.$parent.$parent.$parent.$parent.$parent.$parent.resource;

      // console.log("resource: ", resource);
      const canShow = this.permissions.includes(
        `resources.${this.resource}.show`
      );
      // console.log("permission: ", canShow);
      if (resource && !canShow) {
        let errMsg = `Non hai il permesso di eseguire 'show' sulla risorsa ${resource}`;
        return new Promise((resolve) => {
          this.isLoading = false;
          // this.items = [];
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          // chi chiama la show, deve controllare se response.data.data è false -> esce
          resolve({ data: { data: false } });
        });
      }

      const Repo = RepositoryFactory.get(repository);
      // this.isLoading = true;
      return Repo.show(resourceId, queryString)
        .then((response) => {
          return response;
          // this.beForm[repository] = data.fields;
          // this.beRules = data.rules;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    },
    ...mapGetters("auth", ["customAttributes"]),
  },
  computed: {
    ...mapGetters("auth", ["permissions"]),
  },
  mounted() {},
  beforeMount() {
    // DEBUG
    // console.debug("BEFORE MOUNT:::::::::::::::::::::::::ShowMixin");
    // console.debug(`beforeMount: repository = ${this.repository}`);
    // console.debug(`beforeMount: resource = ${this.resource}`);

    if (!this.repository) {
      console.info("FormMixin: missing repository");
      return;
    }
    // TODO: with same logic, prepare the detailFiels by inserting customAttributes
    // NOTE: before that, need to define a data structure for detailFields suitable to allow also Objects to be easily rendered by SmartCard component
    let ca = this.customAttributes()(this.repository);
    let cf = []; // customFields
    // console.debug("customAttributes: ", ca);
    // extract only the "key" values
    if (typeof ca !== "undefined") {
      this.customInputs[this.repository] = ca
        .filter((e) => e.is_hidden === "N")
        .map((obj) => obj.key);
      if (this.customInputs[this.repository].length) {
        if (this.detailFields) {
          // init custom attribute in detailFields
          this.$set(this.detailFields, "custom", {});
          this.$set(
            this.detailFields.custom,
            "header",
            "Attributi personalizzati"
          );
          this.$set(this.detailFields.custom, "repository", this.repository);
          // this.$set(this.detailFields.custom, 'fields', {})
        }
        // Create the dynamic v-model data property using $set:
        // if (!this.form[this.repository]) {
        //   this.form[this.repository] = {};
        // }
        this.customInputs[this.repository].forEach((element) => {
          // console.debug("customInput:", element);
          // NOTE: since input is created and bound at runtime, must use $set:
          if (this.detailFields) {
            let type;
            let found = ca.find((e) => e.key === element);
            if (found) {
              type = getInputType(found);
            }
            cf.push({
              // label: `attribute_${element}_value`, // NEW (for Dictionary): before was simply element
              label: ca.find((e) => e.key === element).text, // non posso avere l'etichetta nel dizionario
              value: `attributables.${element}`,
              type: type,
            });
          }
        });
        if (this.detailFields) {
          // set custom attribute in detailFields
          this.$set(this.detailFields.custom, "fields", cf);
        }
      }
    }
    // console.debug(
    //   `beforeMount: customInputs = ${JSON.stringify(
    //     this.customInputs[this.repository]
    //   )}`
    // );
  },
};
